import React from 'react';
import RedirectTo from "../../components/shared/RedirectTo/RedirectTo";
import {careersEnabled, getLink, getRoutes} from "../../components/shared/routes";
import OfferPage from "../../components/shared/OfferPage/OfferPage";
import DevOpsLogo from "../../images/Careers/deeplai-career-devops.svg";

const CareersDevOps = () => {
    const routes = getRoutes();

    if (!careersEnabled.list) {
        return <RedirectTo/>;
    }
    if (!careersEnabled.devOps) {
        return <RedirectTo url={getLink([routes.Careers])}/>;
    }

    return (
        <OfferPage
            image={DevOpsLogo}
            imageAlt={'deeplai career devops'}
            name={routes.CareersDevOps.pageTitle}
            scopeOfDuties={<><p>work with a team of developers in the selection of appropriate
                technologies and solutions to manage and support production environments</p>
                <p>implementation and support of implemented technological solutions ensuring the
                    performance, scalability, fault tolerance and properly secured</p><p>implementation
                    of solutions for monitoring and logging events for production systems</p>
                <p>automation of operations related to the management of distributed environments
                    manufacturing</p><p>analysis of the current situation and problem solving</p>
                <p>implementation, updating and management of databases </p><p>installation,
                    configuration, tuning and optimization of existing implemented technological
                    solutions</p></>}
            ourRequirements={<><p>Bachelor or Master degree in Computer Science, Computer Engineering or
                related</p><p>working knowledge of the English language</p><p>good knowledge about AWS
                cloud services configuration and management</p><p>proficiency in the Linux (Debian,
                Ubuntu) systems configuration and management </p><p>good knowledge of computer's
                networks systems, protocols and security</p><p>proficiency in at least one scripting
                language (eg. Bash, Python)</p><p>understanding of issues of high availability and
                monitoring applications</p><p>experience in the administration of web applications (DNS,
                reverse cache, load balancing, proxy servers)</p><p>knowledge of applications
                administration in service-oriented architecture</p><p>knowledge of cluster architecture
                and its management (Kubernetes, Docker)</p></>}
        />
    );
};

export default CareersDevOps;
